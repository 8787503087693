import React from 'react';
import { useRoutes } from "react-router-dom";
import Home from "./pages/index";
import Room from "./pages/rooms/[name]";

function MyRoutes() {
  return useRoutes([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "rooms/:name",
        element: <Room />,
    }
  ]);
}

export default MyRoutes;