import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Home.module.css';

function DemoMeetingTab({ label }) {
  const navigate = useNavigate();
  const [meetingID, setMeetingID] = useState('');

  const startMeeting = () => {
    meetingID ? navigate(`/rooms/${meetingID}`) :
    navigate(`/rooms/${generateRoomId()}`);
  };

  return (
    <div className={styles.tabContent}>
      <p style={{ marginTop: 0 }}>Start a new meeting, or enter a meeting ID below</p>
      <div>
        <div className={styles.helperText}>Meeting ID</div>
        <div>
          <input
            type="text"
            name="meetingID"
            value={meetingID}
            onChange={(e) => setMeetingID(e.target.value)}
            autoFocus={true}
            className={styles.inputField}
          />
        </div>
      </div>
      <button className={styles.startMeeting} onClick={startMeeting}>
        {meetingID ? `Join Meeting ${meetingID}` : 'Start New Meeting'}
      </button>
    </div>
  );
}

const Home = () => {
  return (
    <main className={styles.main} data-lk-theme="default">
      <div className="header">
        <img src="/conqr-logo-dm-svg.svg" alt="CONQR Meet" width="360" height="105" />
        <h2>
          A New World of{' '}
          <a href="https://conqr.com.au" rel="noopener noreferrer">
            Work
          </a>
          .
        </h2>
      </div>
      <DemoMeetingTab label="Demo" />
      <footer data-lk-theme="default">
        Get your top three consultant matches for free.{' '}
        <a href="https://app.conqr.com.au" rel="noopener noreferrer">
          Sign up now
        </a>
        .
      </footer>
    </main>
  );
};

export default Home;

function generateRoomId() {
  return `${randomString(4)}-${randomString(4)}`;
}

function randomString(length) {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}