import { TokenResult } from '../lib/types';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from "firebase/functions";

//! prod firebase config!//
const firebaseConfig = {
  apiKey: "AIzaSyAEYYQBEPXBv2mG_5NPS0HCJ7T0vHxFrGQ",
  authDomain: "conqr-prod-6c1a0.firebaseapp.com",
  projectId: "conqr-prod-6c1a0",
  storageBucket: "conqr-prod-6c1a0.appspot.com",
  messagingSenderId: "404789185569",
  appId: "1:404789185569:web:73d71657bac931122e6760",
  measurementId: "G-W1WE9VKW9V"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const tokenFunction = httpsCallable(functions, 'videoCall');

const roomPattern = /\w{4}\-\w{4}/;

async function handleToken(roomName: string, identity: string, name: string) {
  try {
    // const { roomName, identity, name, metadata } = req.query;

    if (typeof identity !== 'string' || typeof roomName !== 'string') {
      // res.status(403).end();
      return;
    }

    if (Array.isArray(name)) {
      throw Error('provide max one name');
    }

    if (!roomName.match(roomPattern)) {
      // res.status(400).end();
      return;
    }

    const grant = {
      room: roomName,
      roomJoin: true,
      canPublish: true,
      canPublishData: true,
      canSubscribe: true,
    };

    // const token = createToken({ identity, name, metadata }, grant);
    const funcRes = await tokenFunction({grant: grant, id: { identity, name }});
    const token = funcRes.data;
    // console.log('===========================');
    // console.log(token);
    // console.log('===========================');
    const result: TokenResult = {
      identity,
      accessToken: String(token),
    };

    return token;

  } catch (e) {
    return (e as Error).message;
  }
}

export default handleToken;