import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MyRoutes from './Routes'; // assuming you have your routes defined here
import './styles/globals.css'; // assuming that this is your global css file
import { Helmet } from 'react-helmet';
// import '@livekit/components-styles';
// import '@livekit/components-styles/prefabs';

const App = () => {
  return (
    <>
          <Helmet>
          <title>CONQR Meet | A New World of Work</title>
        <meta name="description" content="CONQR connects businesses to consultants without the searching and extravagant fees. Our unique AI matching algorithm does all the heavy lifting, enabling you to get to work faster." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@livekitted" />
        <meta name="twitter:creator" content="@livekitted" />
        <meta property="og:url" content="https://conqr.com.au" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="CONQR Meet" />
        <meta property="og:image" content="https://meet.livekit.io/images/livekit-meet-open-graph.png" />
        <meta property="og:image:width" content="2000" />
        <meta property="og:image:height" content="1000" />
        <meta property="og:image:type" content="image/png" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/images/livekit-apple-touch.png" sizes="180x180" />
        <link rel="mask-icon" href="/images/livekit-safari-pinned-tab.svg" color="#070707" />
        <meta name="theme-color" content="#070707" />
        </Helmet>
        <Router>
          <MyRoutes />
        </Router>
    </>
  );
}

export default App;
