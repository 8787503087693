import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  LiveKitRoom,
  PreJoin,
  LocalUserChoices,
  useToken,
  VideoConference,
  formatChatMessageLinks,
} from '@livekit/components-react';
import '@livekit/components-styles';
import styles from '../../styles/prejoin.module.css';
import { LogLevel, RoomOptions, VideoPresets } from 'livekit-client';
import { DebugMode } from '../../lib/Debug';
// import { TokenResult } from '../../lib/types';
// import { useServerUrl } from '../../lib/client-utils';
import handleToken from '../../methods/fbFunctions';

const Room = () => {
  const { name: roomName } = useParams();
  const navigate = useNavigate();
  const [preJoinChoices, setPreJoinChoices] = useState<LocalUserChoices | undefined>(undefined);

  return (
    <>
      <Helmet>
        <title>CONQR Meet</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main className={styles.container} data-lk-theme="default">
        <div className={styles.logo}>
          <img src="/conqr-logo-dm-svg.svg" alt="CONQR Meet" width="360" height="85" />
        </div>
        {roomName && preJoinChoices ? (
          <div className={styles.innerContent}>
            <div>
              <h4>Meeting ID: {roomName}</h4>
            </div>
            <ActiveRoom
              roomName={roomName}
              userChoices={preJoinChoices}
              onLeave={() => {
                navigate('/');
              }}
            />
          </div>
        ) : (
          <div className={styles.innerContent}>
            <PreJoin
              onError={(err) => console.log('error while setting up prejoin', err)}
              defaults={{
                username: '',
                videoEnabled: true,
                audioEnabled: true,
              }}
              onSubmit={(values) => {
                console.log('Joining with: ', values);
                setPreJoinChoices(values);
              }}
            />
          </div>
        )}
      </main>
    </>
  );
};

export default Room;

type ActiveRoomProps = {
  roomName: string;
  userChoices: {
    username: string;
    videoDeviceId?: string;
    audioDeviceId?: string;
    videoEnabled: boolean;
    audioEnabled: boolean;
  };
  onLeave: () => void;
};

const ActiveRoom: React.FC<ActiveRoomProps> = ({ roomName, userChoices, onLeave }) => {

  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const { region, hq } = useParams();

  useEffect(() => {
    const fetchToken = async () => {
      const res = await handleToken(roomName, userChoices.username, userChoices.username);
      // console.log('+++++++++++++++++++++++++++++');
      // console.log(res);
      // console.log('+++++++++++++++++++++++++++++');
      setToken(String(res) || null);
    }

    fetchToken();
  }, [roomName, userChoices]);

  // const liveKitUrl = useServerUrl(region);
  const liveKitUrl = 'wss://conqrmeet.livekit.cloud';

  
  const roomOptions = useMemo(() => {
    return {
      videoCaptureDefaults: {
        deviceId: userChoices.videoDeviceId || undefined,
        // resolution: VideoPresets.h720,
        // resolution: hq === 'true' ? VideoPresets.h2160 : VideoPresets.h720,
      },
      // publishDefaults: {
        // videoSimulcastLayers: [VideoPresets.h1080, VideoPresets.h720],
          // hq === 'true'
          //   ? [VideoPresets.h1080, VideoPresets.h720]
          //   : [VideoPresets.h540, VideoPresets.h216],
      // },
      audioCaptureDefaults: {
        deviceId: userChoices.audioDeviceId || undefined,
      },
      // adaptiveStream: { pixelDensity: "screen" },
      dynacast: true,
    };
  }, [userChoices, hq]);

  return (
    <>
      {liveKitUrl && token && (
        <LiveKitRoom
          token={token}
          serverUrl={liveKitUrl}
          options={roomOptions}
          video={userChoices.videoEnabled}
          audio={userChoices.audioEnabled}
          onDisconnected={onLeave}
        >
          <VideoConference chatMessageFormatter={formatChatMessageLinks} />
          <DebugMode logLevel={LogLevel.info} />
        </LiveKitRoom>
      )}
    </>
  );
};